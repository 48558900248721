<div class="dropdown">
  <div class="dropdown-header flex items-center justify-between">
    <div class="flex items-center">
      <div class="dropdown-heading-icon flex items-center justify-center">
        <mat-icon svgIcon="mat:person"></mat-icon>
      </div>
      <div class="dropdown-heading">{{username}}</div>
    </div>


  </div>

  <div class="dropdown-footer flex items-center justify-between">

    <a (click)="close()"  color="primary" mat-button>Logout</a>
  </div>
</div>



