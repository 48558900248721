import { ChangeDetectorRef, Component, ElementRef, HostBinding, Input, OnInit } from '@angular/core';
import { LayoutService } from '../../services/layout.service';
import { ConfigService } from '../../config/config.service';
import { map, startWith, switchMap } from 'rxjs/operators';
import { NavigationService } from '../../services/navigation.service';
import { PopoverService } from '../../components/popover/popover.service';
import { MegaMenuComponent } from '../../components/mega-menu/mega-menu.component';
import { Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from '../../../app/pages/pages/auth/login/login.service';
import { SearchingService } from '../../../app/pages/apps/searching/searching.service';
import { ToolbarserviceService } from './toolbarservice.service';
import { JWTTokenService } from '../../../app/pages/pages/auth/JWTToken.service';

import { Router, ActivatedRoute } from '@angular/router';
import { DashboardComponent } from '../../../app/pages/apps/dashboard/dashboard.component';

@Component({
  selector: 'vex-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

  @Input() mobileQuery: boolean;

  @Input()
  @HostBinding('class.shadow-b')
  hasShadow: boolean;
  rates:any= {
    "USD": 1,
    "AED": 3.67,
    "AFN": 68.45,
    "ALL": 88.75,
    "AMD": 387.48,
    "ANG": 1.79,
    "AOA": 955.31,
    "ARS": 968.75,
    "AUD": 1.45,
    "AWG": 1.79,
    "AZN": 1.7,
    "BAM": 1.75,
    "BBD": 2,
    "BDT": 119.54,
    "BGN": 1.75,
    "BHD": 0.376,
    "BIF": 2902.43,
    "BMD": 1,
    "BND": 1.28,
    "BOB": 6.92,
    "BRL": 5.44,
    "BSD": 1,
    "BTN": 83.66,
    "BWP": 13.11,
    "BYN": 3.25,
    "BZD": 2,
    "CAD": 1.35,
    "CDF": 2853.11,
    "CHF": 0.847,
    "CLP": 912.68,
    "CNY": 7,
    "COP": 4204.95,
    "CRC": 521.04,
    "CUP": 24,
    "CVE": 98.72,
    "CZK": 22.53,
    "DJF": 177.72,
    "DKK": 6.68,
    "DOP": 60.13,
    "DZD": 132.3,
    "EGP": 48.39,
    "ERN": 15,
    "ETB": 118.26,
    "EUR": 0.895,
    "FJD": 2.19,
    "FKP": 0.746,
    "FOK": 6.68,
    "GBP": 0.746,
    "GEL": 2.73,
    "GGP": 0.746,
    "GHS": 15.83,
    "GIP": 0.746,
    "GMD": 70.69,
    "GNF": 8665.51,
    "GTQ": 7.73,
    "GYD": 209.31,
    "HKD": 7.78,
    "HNL": 24.88,
    "HRK": 6.75,
    "HTG": 131.91,
    "HUF": 354.53,
    "IDR": 15129.78,
    "ILS": 3.7,
    "IMP": 0.746,
    "INR": 83.66,
    "IQD": 1310.15,
    "IRR": 42027.54,
    "ISK": 135.02,
    "JEP": 0.746,
    "JMD": 156.99,
    "JOD": 0.709,
    "JPY": 144.86,
    "KES": 129.15,
    "KGS": 84.21,
    "KHR": 4066.62,
    "KID": 1.45,
    "KMF": 440.47,
    "KRW": 1319.63,
    "KWD": 0.305,
    "KYD": 0.833,
    "KZT": 478.6,
    "LAK": 21984.79,
    "LBP": 89500,
    "LKR": 299.88,
    "LRD": 194.39,
    "LSL": 17.19,
    "LYD": 4.74,
    "MAD": 9.68,
    "MDL": 17.42,
    "MGA": 4535,
    "MKD": 54.99,
    "MMK": 2101.01,
    "MNT": 3377.71,
    "MOP": 8.01,
    "MRU": 39.7,
    "MUR": 45.71,
    "MVR": 15.4,
    "MWK": 1741.53,
    "MXN": 19.61,
    "MYR": 4.14,
    "MZN": 63.93,
    "NAD": 17.19,
    "NGN": 1639.52,
    "NIO": 36.85,
    "NOK": 10.54,
    "NPR": 133.86,
    "NZD": 1.58,
    "OMR": 0.384,
    "PAB": 1,
    "PEN": 3.74,
    "PGK": 3.93,
    "PHP": 55.92,
    "PKR": 277.77,
    "PLN": 3.82,
    "PYG": 7795.55,
    "QAR": 3.64,
    "RON": 4.46,
    "RSD": 104.81,
    "RUB": 92.58,
    "RWF": 1351.32,
    "SAR": 3.75,
    "SBD": 8.48,
    "SCR": 13.77,
    "SDG": 512.18,
    "SEK": 10.12,
    "SGD": 1.28,
    "SHP": 0.746,
    "SLE": 22.57,
    "SLL": 22570.64,
    "SOS": 571.65,
    "SRD": 30.68,
    "SSP": 3152.66,
    "STN": 21.94,
    "SYP": 12898.06,
    "SZL": 17.19,
    "THB": 32.45,
    "TJS": 10.67,
    "TMT": 3.5,
    "TND": 3.03,
    "TOP": 2.3,
    "TRY": 34.18,
    "TTD": 6.78,
    "TVD": 1.45,
    "TWD": 31.68,
    "TZS": 2727.86,
    "UAH": 41.19,
    "UGX": 3688.46,
    "UYU": 42.25,
    "UZS": 12732.93,
    "VES": 36.87,
    "VND": 24653.22,
    "VUV": 118.05,
    "WST": 2.7,
    "XAF": 587.29,
    "XCD": 2.7,
    "XDR": 0.741,
    "XOF": 587.29,
    "XPF": 106.84,
    "YER": 250.28,
    "ZAR": 17.19,
    "ZMW": 26.43,
    "ZWL": 14
  }
  logo='../../../assets/taf_horiz.jpeg'
  isConditionTrue:boolean
  navigationItems = this.navigationService.items;

  isHorizontalLayout$: Observable<boolean> = this.configService.config$.pipe(map(config => config.layout === 'horizontal'));
  isVerticalLayout$: Observable<boolean> = this.configService.config$.pipe(map(config => config.layout === 'vertical'));
  isNavbarInToolbar$: Observable<boolean> = this.configService.config$.pipe(map(config => config.navbar.position === 'in-toolbar'));
  isNavbarBelowToolbar$: Observable<boolean> = this.configService.config$.pipe(map(config => config.navbar.position === 'below-toolbar'));
  userVisible$: Observable<boolean> = this.configService.config$.pipe(map(config => config.toolbar.user.visible));

  megaMenuOpen$: Observable<boolean> = of(false);
  currency: any;
  currencyy: string;
  tnd:any;
  devise: any;
  solde: number;
  convertedAmount: number;

  constructor(private layoutService: LayoutService,
              private configService: ConfigService,private router: Router,
              private navigationService: NavigationService,
              private popoverService: PopoverService,
                public loginService: LoginService,
                private LoginService: LoginService,
                private toolbarService: ToolbarserviceService, 
              private jwttokenservice: JWTTokenService,

                private cd :ChangeDetectorRef, 

              private translate: TranslateService, ) {
                this.translate.use('en');
               }
               isDropdownOpen = false;

               toggleDropdown() {
                 this.isDropdownOpen = !this.isDropdownOpen;
               }
               menuVisible: boolean = false;

               toggleMenu() {
                 this.menuVisible = !this.menuVisible;
               }
             
           
               getSolde() {
                this.LoginService.currentSolde.subscribe((solde) => {
            
                  this.convertedAmount = solde;
            
                });
              }
              getRole() {
                this.LoginService.currentrole.subscribe((role) => {
                  if(role==='admin' || this.jwttokenservice.getUser()=='info@rafahiabooking.com' || this.jwttokenservice.getUser()=='reservation@rafahiabooking.com'){
                    this.isConditionTrue=true

                   }
               else{
            this.isConditionTrue=false

                }
            
                });
              }
              getdatadevise() {
                this.LoginService.currentDataDevise.subscribe((DeviseCurrency) => {
            
                  this.DeviseCurrency = DeviseCurrency;
                  console.log("DeviseCurrencyyyy***/", DeviseCurrency)
            
                });
              }
               getdata(){
                this.loginService.currentData.subscribe((currency) => {
      
                 
                 
                  console.log("currency***/",currency)
                  this.tnd=this.currency
                   this.DeviseCurrency=this.currency
                  console.log("tnd/currency***",this.tnd)
                  
                   this.cd.detectChanges()
                 

              
                  
                });
            }
               ngOnInit() {
              this.getdata()
              
              this.getdatadevise()
              
              this.getSolde()
              this.getRole()
              }
             
  
  
              DeviseCurrency=''
             
  openQuickpanel(): void {
    this.layoutService.openQuickpanel();
  }



  openSidenav(): void {
    this.layoutService.openSidenav();
  }
  changeLang(language: string) {
    this.translate.use(language);
  }


  convertCurrency(solde,from,to): void {
    console.log("this.convertedAmount ",solde  ,from)

    solde = solde / this.rates[from];

      console.log("this.convertedAmount ",solde  ,'USD')

      this.convertedAmount = solde * this.rates[to];
      console.log("this.convertedAmount ",this.convertedAmount  ,to)


      
      console.log("this.convertedAmount ",this.convertedAmount )
       this.cd.detectChanges
    
  }
  changeDevise(devise: string) {
    this.convertCurrency(this.convertedAmount,this.DeviseCurrency,devise)
    this.DeviseCurrency=devise
    this.loginService.updateCurrency(this.DeviseCurrency)

    this.cd.detectChanges


 
  }

  openMegaMenu(origin: ElementRef | HTMLElement): void {
    this.megaMenuOpen$ = of(
      this.popoverService.open({
        content: MegaMenuComponent,
        origin,
        offsetY: 12,
        position: [
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top'
          },
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
          },
        ]
      })
    ).pipe(
      switchMap(popoverRef => popoverRef.afterClosed$.pipe(map(() => false))),
      startWith(true),
    );
  }

  openSearch(): void {
    this.layoutService.openSearch();
  }
}