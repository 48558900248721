// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  url_CashBookingTnBeds:'https://aurora.dotcom.tn/api/xml/bookingTunisiaBedsSoldeOrCash',
  url_bookingTnBedsSolde:'https://aurora.dotcom.tn/api/xml/bookingTnBedsSolde',
  url_configs_booking:'https://aurora.dotcom.tn/api/b2c/B2cBookConfig',
  
  url_ConfirmBookTnBeds:'https://aurora.dotcom.tn/api/xml/ConfirmBooking_B2B_TunisiaBeds',
  url_ConfirmBookingMyGo:'https://aurora.dotcom.tn/api/xml/ConfirmBooking_B2B_MyGo',
  url_CancelBookingOctasoft:'https://aurora.dotcom.tn/api/xml/CancelbookingOcatasoft',
  url_OnLineOctasoft:'https://aurora.dotcom.tn/api/xml/OctasoftOnLineStepZero',

  urlReservations: 'https://aurora.dotcom.tn/api/BookingList',
  urlGetAccess: 'https://aurora.dotcom.tn/api/getAccessModules',
  urlget_hotelsmarge: 'https://aurora.dotcom.tn/api/getHotelsMargesList',
  urlGet_hotelsDotcom:'https://aurora.dotcom.tn/api/getAllHotelsByCountryAndCity',
  Url_get_Rates: 'https://aurora.dotcom.tn/api/getAllRates',
  urlRoomsCityNet: 'https://aurora.dotcom.tn/api/GetHotelRoomsCityNet',
  UrlsearchFlightsCityNet:'https://aurora.dotcom.tn/api/searchFlightsCityNet',
  Url_PUT_Rates: 'https://aurora.dotcom.tn/api/updateRates',
  Url_get_Links: 'https://aurora.dotcom.tn/api/getAllLinksPayment',
  Url_send_Links: 'https://aurora.dotcom.tn/api/sendLinkPayment',
  urlListHotel: 'https://www.90traveltunisia.com/90traveltunisia_erp/Hotel/readAll',
  urlTestXmlin: 'https://aurora.dotcom.tn/api/TestXmlIn',
  urlAutoComplete: 'https://aurora.dotcom.tn/api/AutoComplete',
  urlSearch: 'https://aurora.dotcom.tn/api/HotelSearch',
  HotelSearchAllSources: 'https://aurora.dotcom.tn/api/HotelSearchAllSources',
  supplementsCreateUpdateGetDelete: 'https://aurora.dotcom.tn/api/contract/supplements',
  urlReservationsPerAgency:'https://aurora.dotcom.tn/api/BookingListByAgency',
  url_PostHotels_b2c:'https://aurora.dotcom.tn/api/b2c/B2cStaticHotels',
  url_update_Global_marge:'https://aurora.dotcom.tn/api/updateOutsideGlobalMarge',
  url_updateConfig:'https://aurora.dotcom.tn/api/updateConfig',
  
  urlGet_marge:'https://aurora.dotcom.tn/api/All_marge_hotel',
  urlPut_marge:'https://aurora.dotcom.tn/api/marge_hotel',
  urlgetGlobMarge:'https://aurora.dotcom.tn/api/getGlobalMarge',
  urlSession: 'https://energiebooking.com/energiebookingnew/Session',
  urlmappagelist: 'https://aurora.dotcom.tn/api/mappagelist',
  urlstaticlist: 'https://aurora.dotcom.tn/api/list',
  urlLogin: 'https://aurora.dotcom.tn/api/Login',
  urlRegister: 'https://aurora.dotcom.tn/api/RegisterClient',
  urlResetRequest: 'https://aurora.dotcom.tn/api/ResetPasswordRequest',
  urlUpdatePassword: 'https://aurora.dotcom.tn/api/ResetPassword',
  urlUpdateMappage: 'https://aurora.dotcom.tn/api/Mappage',
  urlSelectMappage: 'https://aurora.dotcom.tn/api/Mappageselect',
  urlReverseSelectMappage: 'https://aurora.dotcom.tn/api/Mappagereverseselect',
  urlListCountry: 'https://aurora.dotcom.tn/api/listcountry',
  urlConfirmRegistration: "https://aurora.dotcom.tn/api/ConfirmRegistration",
  urlXmlout: 'https://aurora.dotcom.tn/api/XmlOut',
  urlXmloutdelete:'https://aurora.dotcom.tn/api/XmlOut',
  urlAddAgency: 'https://aurora.dotcom.tn/api/AddAgency',
  urlUpdateAgencyInProd: 'https://aurora.dotcom.tn/api/UpdateAgencyInProd',
  urlUpdateAgencyStatus: 'https://aurora.dotcom.tn/api/UpdateAgencyStatus',
  urlGroups: 'https://aurora.dotcom.tn/api/Group',
  urlGroupChange: 'https://aurora.dotcom.tn/api/GroupChange',
  urlAgenceBygroup: 'https://aurora.dotcom.tn/api/AgenceByGroup',
  urlAdd_hotel: 'https://aurora.dotcom.tn/api/Hotel',
  urlget_hotel: 'https://aurora.dotcom.tn/api/Hotel_Pages',
  urlPut_hotel: 'https://aurora.dotcom.tn/api/Hotel',
  urlDelete_hotel: 'https://aurora.dotcom.tn/api/Hotel',
  urlGet_city: 'https://aurora.dotcom.tn/api/City',
  urlGet_country: 'https://aurora.dotcom.tn/api/Country',
  urlGet_facilitie: 'https://aurora.dotcom.tn/api/Facility',
  urlPost_facilitie: 'https://aurora.dotcom.tn/api/Facility',
  urlPut_facilitie: 'https://aurora.dotcom.tn/api/Facility',
  urlDelete_facilitie: 'https://aurora.dotcom.tn/api/Facility',
  urlPost_facilitie_id: 'https://aurora.dotcom.tn/api/AssignFacility',
  urlDashboard: 'https://aurora.dotcom.tn/api/Dashboard?clientid=21',
  urlGetListGroupe: 'https://aurora.dotcom.tn/api/Group',
  urlGetAgences: 'https://aurora.dotcom.tn/api/XmlOut',
  urlFileUpload: 'https://aurora.dotcom.tn/api/FileUpload',
  urlFileUploadImageHotel: 'https://aurora.dotcom.tn/api/HotelImageUpload',
  urllogos:'https://aurora.dotcom.tn/api/logo',
  urlUser:"https://aurora.dotcom.tn/api/User",
  urlupdateUser:"https://aurora.dotcom.tn/api/updateUser",
  urlGet_image:'https://aurora.dotcom.tn/api/HotelImage/',
  urlPost_image:'https://aurora.dotcom.tn/api/Images/Hotel',
  urlDelete_image:'https://aurora.dotcom.tn/api/HotelImage/',
  urlupdatestatusUser:"https://aurora.dotcom.tn/api/UserActivate",
  urlGet_hotel:'https://aurora.dotcom.tn/api/Hotel',
  urlGet_Contart:'https://aurora.dotcom.tn/api/Contrat',
  url_post_contrat:'https://aurora.dotcom.tn/api/Contrat',
  urlGet_Room:'https://aurora.dotcom.tn/api/Room', /*ines@dotcom.tn Form array Period */
  url_post_room_contrat:'https://aurora.dotcom.tn/api/RoomContrat', /*ij@dotcom.tn  api post room contrat*/
  url_get_arrangement:'https://aurora.dotcom.tn/api/Arrangement',
  url_post_board_contrat:'https://aurora.dotcom.tn/api/BoardContrat',
  urlGet_RoomPrice:'https://aurora.dotcom.tn/api/Room_price',
  url_post_periode_contrat:'https://aurora.dotcom.tn/api/Period',
  url_Get_board:'https://aurora.dotcom.tn/api/Arrangement',
  url_post_board:'https://aurora.dotcom.tn/api/Arrangement/',
  url_put_board:'https://aurora.dotcom.tn/api/Arrangement/',
  url_delete_board:'https://aurora.dotcom.tn/api/Arrangement/',
  url_Get_country:'https://aurora.dotcom.tn/api/Country',
  url_Post_country:'https://aurora.dotcom.tn/api/Country',
  url_delete_country:'https://aurora.dotcom.tn/api/Country/',
  url_Put_country:'https://aurora.dotcom.tn/api/Country/',
  url_put_room:'https://aurora.dotcom.tn/api/Room/',
  url_delete_room:'https://aurora.dotcom.tn/api/Room/',
  url_post_room:'https://aurora.dotcom.tn/api/Room/',
  urlGet_ville:'https://aurora.dotcom.tn/api/City',
  urlpost_ville:'https://aurora.dotcom.tn/api/City',
  url_put_ville:'https://aurora.dotcom.tn/api/City/',
  url_delete_ville:'https://aurora.dotcom.tn/api/City/',
  urlPostBaseRoomPrice: 'https://aurora.dotcom.tn/api/BasePrice',
  url_Get_Period: 'https://aurora.dotcom.tn/api/Period/',
  url_Get_Period_Contract: 'https://aurora.dotcom.tn/api/PeriodParContrat/',
  url_Get_Room: 'https://aurora.dotcom.tn/api/RoomContratParContrat',
  url_Get_RoomType: 'https://aurora.dotcom.tn/api/RoomTypePerRoom',
  urlGet_board: 'https://aurora.dotcom.tn/api/BoardContratParContrat',
  url_Get_Periode_Of_Contrat: 'https://aurora.dotcom.tn/api/PeriodParContrat',
  url_Get_ChildPrice_Of_Contrat: 'https://aurora.dotcom.tn/api/ChildPrice/Contrat',
  url_Get_Boards_Of_Contrat: 'https://aurora.dotcom.tn/api/BoardContratParContrat',
   url_Get_Room_Of_Contart: 'https://aurora.dotcom.tn/api/RoomContratParContrat',
  urlGet_ContartAutoComplete: 'https://aurora.dotcom.tn/api/ContratAutoComplete',
  url_Post_ChildPrice:'https://aurora.dotcom.tn/api/ChildPrice',
  url_Put_ChildPrice:'https://aurora.dotcom.tn/api/ChildPrice/Contrat/',
  url_get_ChildPrice:'https://aurora.dotcom.tn/api/ChildPrice/',
  url_Put_ChildPrices:'https://aurora.dotcom.tn/api/ChildPrice/',
  url_Delete_ChildPrices:'https://aurora.dotcom.tn/api/ChildPrice/',
  url_get_contrat:'https://aurora.dotcom.tn/api/Contrat',
  url_Post_SpecialOffer:'https://aurora.dotcom.tn/api/SpecialOffer',
  URL_ActivateSpecial_Offer:'https://aurora.dotcom.tn/api/activateSepcialOffre',


  
  url_get_ChaineHotel:'https://aurora.dotcom.tn/api/HotelChain',
  url_Post_booking:'https://aurora.dotcom.tn/api/EarlyBooking',
  url_Post_DayPromotion:'https://aurora.dotcom.tn/api/DayPromotion',
  url_Post_stay:'https://aurora.dotcom.tn/api/LongStay',
  url_Post_HonneyMoon:'https://aurora.dotcom.tn/api/HoneyMoon',
  url_Post_RoomPromotion:'https://aurora.dotcom.tn/api/RoomPromotion',
  url_Post_PaxPromotion:'https://aurora.dotcom.tn/api/PaxPromotion',
  url_Get_MinimumStay:'https://aurora.dotcom.tn/api/MinimumStayTab',
  url_Post_MinimumStay:'https://aurora.dotcom.tn/api/MinimumStay',
  url_Get_WeekendDay:'https://aurora.dotcom.tn/api/WeekendDay',
  url_Post_weekendDay:'https://aurora.dotcom.tn/api/WeekendDay',
  url_Post_StopSale:'https://aurora.dotcom.tn/api/StopSale',
  url_Get_StopSale:'https://aurora.dotcom.tn/api/StopSale',
  url_Post_Sale:'https://aurora.dotcom.tn/api/StopSale',
  url_Get_ChildPrice_Of_Contart:'https://aurora.dotcom.tn/api/ChildPrice/Contrat',
  url_Get_SpecialOffer_Of_Contart:'https://aurora.dotcom.tn/api/SpecialOffer/Contrat',
  url_Get_EarlyBooking_Of_Contart:'https://aurora.dotcom.tn/api/EarlyBooking/Offer',
  url_DayPromotion_Of_Contart:'https://aurora.dotcom.tn/api/DayPromotion/Offer',
  url_LongStay_Of_Contart:'https://aurora.dotcom.tn/api/LongStay/Offer',
  url_Get_HoneyMoonersSpo_Of_Contart:'https://aurora.dotcom.tn/api/HoneyMoon/Offer',
  url_Get_RoomPromotion_Of_Contrat:'https://aurora.dotcom.tn/api/RoomPromotion/Offer',
  url_Get_PaxPromotion_Of_Contrat:'https://aurora.dotcom.tn/api/PaxPromotion/Offer',
  url_Get_WeekendDay_Of_Contrat:'https://aurora.dotcom.tn/api/WeekendDay/Offer',
  url_Get_MinimumStay_Of_Contrat:'https://aurora.dotcom.tn/api/MinimumStay/Offer',
  url_Get_StopSale_Of_Contrat:'https://aurora.dotcom.tn/api/StopSale/Offer',
  url_Get_EarlyBooking:'https://aurora.dotcom.tn/api/EarlyBooking',
  url_Post_Log:'https://aurora.dotcom.tn/api/Log/Filter',
  url_Get_Reglement:'https://aurora.dotcom.tn/api/Reglement',
  url_Post_Reglement:'https://aurora.dotcom.tn/api/Reglement',
  url_Put_Reglement:'https://aurora.dotcom.tn/api/Reglement',
  url_Delete_Reglement:'https://aurora.dotcom.tn/api/Reglement',
  url_Get_Allotement_Of_Contrat:'https://aurora.dotcom.tn/api/AllotementParContrat',
  url_Get_BasePrices_Of_Contrat:'https://aurora.dotcom.tn/api/BasePriceParContrat',
  url_GetReglement_Of_Agence:'https://aurora.dotcom.tn/api/Reglement/Agence',
  url_Get_Reglement_id:'https://aurora.dotcom.tn/api/Reglement',
  url_Get_Integrateur:'https://aurora.dotcom.tn/api/Integrateur',
  url_Post_Integrateur:'https://aurora.dotcom.tn/api/Integrateur',
  url_Put_Integrateur:'https://aurora.dotcom.tn/api/Integrateur',
  url_Delete_Integrateur:'https://aurora.dotcom.tn/api/Integrateur',
  url_Get_ListPays:'https://aurora.dotcom.tn/api/Country',
  urlGet_countryList: 'https://aurora.dotcom.tn/api/mappagelistcountry',
  url_Post_mappage: 'https://aurora.dotcom.tn/api/Mappage',
  urlGet_cityList:'https://aurora.dotcom.tn/api/getAllCitiesByCountry',
  urlXmlin: 'https://aurora.dotcom.tn/api/XmlIn',
  url_get_Hotel:'https://aurora.dotcom.tn//api/mappagelisthoteldotcom',
  url_get_Hotelxml:'https://aurora.dotcom.tn/api/mappagelisthotel',
  url_get_hotel:'https://aurora.dotcom.tn/api/Hotel',
  url_Get_Reglement_Of_Agence:'https://aurora.dotcom.tn/api/Reglement/Agence',
  url_Get_Agency:'https://aurora.dotcom.tn/api/XmlOut',
  urlPostFacture:'https://aurora.dotcom.tn/api/Facture',
  urlPutFacture:'https://aurora.dotcom.tn/api/Facture',
  urlGenFacture:'https://aurora.dotcom.tn/api/ERP/createFacture',
  urlDeleteFActure:'https://aurora.dotcom.tn/api/Facture',
  urlGetFActure:'https://aurora.dotcom.tn/api/Facture',
  url_Get_RecuList:'https://aurora.dotcom.tn/api/Recu',
  url_Delete_Recu:'https://aurora.dotcom.tn/api/Recu',
  url_Post_Recu:'https://aurora.dotcom.tn/api/Recu',
  url_Get_Chaine:'https://aurora.dotcom.tn/api/HotelChain',
  urlGet_delete_contrat:'https://aurora.dotcom.tn/api/Contrat',
  urlGet_delete_period:'https://aurora.dotcom.tn/api/Period',
  urlGet_delete_room:'https://aurora.dotcom.tn/api/RoomContrat',
  urlGet_delete_board:'https://aurora.dotcom.tn/api/BoardContrat',
  urlGet_delete_child:'https://aurora.dotcom.tn/api/ChildPrice',
  urlGetSumAllData:'https://aurora.dotcom.tn/api/getSumAllData',
  urlGetDataByAgency:'https://aurora.dotcom.tn/api/getDataByAgency',
  urlGetDataByHotel:'https://aurora.dotcom.tn/api/getDataByHotel',
  
  
  url_post_allotement_contrat:'https://aurora.dotcom.tn/api/Allotement',
  url_Post_Country:'https://aurora.dotcom.tn/api/Country',
  url_Put_Country:'https://aurora.dotcom.tn/api/Country',
  url_delete_Pays:'https://aurora.dotcom.tn/api/Country',
  url_add_formJuridique:'https://aurora.dotcom.tn/api/Forme_Juridique',
  url_Get_weekend:'https://aurora.dotcom.tn/api/WeekendDay',
  url_Get_weekendForContract:'https://aurora.dotcom.tn/api//MinimumStayTab/',
  urlGet_RoomType:'https://aurora.dotcom.tn/api/RoomType',
  url_post_RoomType:'https://aurora.dotcom.tn/api/RoomType',
  url_put_RoomType:'https://aurora.dotcom.tn/api/RoomType',
  url_delete_RoomType:'https://aurora.dotcom.tn/api/RoomType',
  urlGet_Agence:'https://aurora.dotcom.tn/api/XmlOut',
  URLPost_RoomPrice:'https://aurora.dotcom.tn/api/Room_price',
  URLPut_RoomPrice:'https://aurora.dotcom.tn/api/Room_priceParContrat/',
  URLPost_TypePrice:'https://aurora.dotcom.tn/api/RoomType_price',
  URLPut_TypePrice:'https://aurora.dotcom.tn/api/RoomType_priceParContrat/',
  URLPost_Board:'https://aurora.dotcom.tn/api/Arrangement_prices',
  urlGet_Calculate_OfContrat:'https://aurora.dotcom.tn/api/CalculateAll',
  url_Get_Chain:'https://aurora.dotcom.tn/api/HotelChain',
  url_Post_Chain:'https://aurora.dotcom.tn/api/HotelChain',
  url_update_Chain:'https://aurora.dotcom.tn/api/HotelChain',
  url_Delete_Chain:'https://aurora.dotcom.tn/api/HotelChain',
  urlGet_Solde:'https://aurora.dotcom.tn/api/Solde_Historique',
  urlPost_Solde:'https://aurora.dotcom.tn/api/Solde_Historique',
  urlDelete_solde:'https://aurora.dotcom.tn/api/Solde_Historique',
  urlPut_Solde:'https://aurora.dotcom.tn/api/Solde_Historique',
  url_Confirmer_Reservation:'https://aurora.dotcom.tn/api/ConfirmBooking',
  urlPrintVoucher:'https://aurora.dotcom.tn/api/voucher_hotel/DOTCOM_Voucher',
  urlPrintVoucherMyGo:'https://aurora.dotcom.tn/api/voucher_MyGo/',
  urlPrintVoucherTnBeds:'https://aurora.dotcom.tn/api/voucher_TunisiaBeds/',

  
  urlPrintVoucherAgency:'https://aurora.dotcom.tn/api/voucher_agency/DOTCOM_Voucher_Agency_',
  urlPrintVoucherCustomer:'https://aurora.dotcom.tn/api/voucher_customer/DOTCOM_Customer_Voucher_',
  url_cancel:'https://aurora.dotcom.tn/api/CancelBooking',
  urlXmloutupdate:'https://aurora.dotcom.tn/api/XmlOut',
  url_get_hotell:'https://aurora.dotcom.tn/api/Hotel',
  url_booking:'https://aurora.dotcom.tn/api/HotelBooking',
  url_GetAgency:'https://aurora.dotcom.tn/api/XmlOut',
  url_Get_Encaissement:'https://aurora.dotcom.tn/api/Payment',
  url_Post_Encaissement:'https://aurora.dotcom.tn/api/Payment',
  url_Put_Encaissement:'https://aurora.dotcom.tn/api/Payment',
  url_delete_Encaissement:'https://aurora.dotcom.tn/api/Payment',
  urlGet_delete_cancellation:'https://aurora.dotcom.tn/api/CancellationPolicy',
  url_Get_Decaissement:'https://aurora.dotcom.tn/api/Payment',
  url_Post_Decaissement:'https://aurora.dotcom.tn/api/Payment',
  url_Put_Decaissement:'https://aurora.dotcom.tn/api/Payment',
  url_delete_Decaissement:'https://aurora.dotcom.tn/api/Payment',
  url_Get_hotelList:'https://aurora.dotcom.tn/api/Hotel',
  urlGet_Arrangement:'https://aurora.dotcom.tn/api/Arrangement',
  url_Get_agence_id: 'https://aurora.dotcom.tn/api/XmlOut',
  urlGet_RoomtypePrice: 'https://aurora.dotcom.tn/api/RomType_price',
  urlGet_ArrangementPrice:'https://aurora.dotcom.tn/api/Arrangement_Prices',
  urlPost_Assignfacility:'https://aurora.dotcom.tn/api/AssignFacility',
  urlAgency:'https://aurora.dotcom.tn/api/XmlOut',
  urlxmlout:'https://aurora.dotcom.tn/api/XmlOut',
  url_Get_Contract:'https://aurora.dotcom.tn/api/Contrat',
  url_delete_sale:'https://aurora.dotcom.tn/api/Contrat',
  urlGet_put_sale:'https://aurora.dotcom.tn/api/StopSale',
  url_Get_sale:'https://aurora.dotcom.tn/api/StopSale',
  url_Get_Sale:'https://aurora.dotcom.tn/api/StopSale',
  url_delete_sales:'https://aurora.dotcom.tn/api/StopSale',
  url_update_status_sales:'https://aurora.dotcom.tn/api/UpdateStatusStopSale',
  urlUpdatePermissionUser:'https://aurora.dotcom.tn/api/UpdatePermissionUser',
  urlGet_AccessModule:'https://aurora.dotcom.tn/api/ModuleAccess',
  urlretrieveGroupesUsersPermissions:'https://aurora.dotcom.tn/api/retrieveGroupesUsersPermissions',
  url_Get_Module:'https://aurora.dotcom.tn/api/ModuleAccess',
  url_put_Module:'https://aurora.dotcom.tn/api/ModuleAccess',
  urlGet_put_contrat:'https://aurora.dotcom.tn/api/Contrat',
  url_put_periode:'https://aurora.dotcom.tn/api/Period',
  url_put_Room:'https://aurora.dotcom.tn/api/RoomContrat',
  url_put_Board:'https://aurora.dotcom.tn/api/BoardContrat',
  url_put_BasePrice:'https://aurora.dotcom.tn/api/BasePrice',
  url_post_BasePrice:'https://aurora.dotcom.tn/api/BasePrice',
  url_put_Promo:'https://aurora.dotcom.tn/api/PaxPromotion',
  url_get_specialofer:'https://aurora.dotcom.tn/api/SpecialOffer',
  urlGet_delete_offer:'https://aurora.dotcom.tn/api/SpecialOffer',
  url_put_Offer:'https://aurora.dotcom.tn/api/SpecialOffer',
  url_put_DayPromo:'https://aurora.dotcom.tn/api/DayPromotion',
  urlGet_Promotion:'https://aurora.dotcom.tn/api/DayPromotion',
  url_put_longstay:'https://aurora.dotcom.tn/api/LongStay',
  url_put_honey:'https://aurora.dotcom.tn/api/HoneyMoon',
  url_put_RoomP:'https://aurora.dotcom.tn/api/RoomPromotion',
  urlPostCancellationCondition:"https://aurora.dotcom.tn/api/CancellationPolicy",
  url_put_weekend:'https://aurora.dotcom.tn/api/WeekendDay',
  url_Get_Cancellation_Conditions_Of_Contrat:"https://aurora.dotcom.tn/api/CancellationPolicyparContrat",
  url_Get_Cancellations_Period_Type:"https://aurora.dotcom.tn/api/getCancellationsByPeriodType",
  url_put_periode_contrat:"https://aurora.dotcom.tn/api/PeriodParContrat/",
  url_put_room_contrat:"https://aurora.dotcom.tn/api/RoomContratParContrat/",
  url_put_board_contrat:"https://aurora.dotcom.tn/api/BoardContratParContrat/",
  url_put_allotement_contrat:"https://aurora.dotcom.tn/api/AllotementParContrat/",
  urlPutCancellationCondition:"https://aurora.dotcom.tn/api/CancellationPolicyparContrat",
  url_Get_ChildPrices_Of_Contrat:"https://aurora.dotcom.tn/api/ChildPrice/Contrat/",
  url_ActivateContract:"https://aurora.dotcom.tn/api/ActivateContrat",
  url_DuplicateContrat:"https://aurora.dotcom.tn/api/Contrat",
  url_logout:"https://aurora.dotcom.tn/api/Logout",
  url_get_crmData:"https://aurora.dotcom.tn/api/CRM_client",
  url_post_crm:"https://aurora.dotcom.tn/api/CRM_client",
  url_put_crm:"https://aurora.dotcom.tn/api/CRM_client",
  url_delete_crm:"https://aurora.dotcom.tn/api/CRM_client",
  url_Get_Groups: 'https://aurora.dotcom.tn/api/Group',
  url_Put_Early: 'https://aurora.dotcom.tn/api/EarlyBooking/Offer',
  url_put_stay:'https://aurora.dotcom.tn/api/MinimumStay/Offer',
  url_post_report:'https://aurora.dotcom.tn/api/ReportBooking',
  url_get_report:'https://aurora.dotcom.tn/api/ReportBooking',
  url_delete_report:'https://aurora.dotcom.tn/api/ReportBooking',
  url_get_export:'https://aurora.dotcom.tn/api/ReportBooking',
  url_Get_Comparator:'https://aurora.dotcom.tn/api/Comparator',
  url_Put_Comparator:'https://aurora.dotcom.tn/api/Comparator',
  url_Delete_Comparator:'https://aurora.dotcom.tn/api/Comparator',
  url_Get_adminbyid:'https://aurora.dotcom.tn/api/Admin',
  url_Post_Solde:'https://aurora.dotcom.tn/api/AddSoldeAdmin',
  url_get_log:'https://aurora.dotcom.tn/api/Log/Filter',
  url_Get_List_Admin_Group:'https://aurora.dotcom.tn/api/GroupAdmin',
  url_Post_AdminGroup:'https://aurora.dotcom.tn/api/GroupAdmin',
  url_Put_AdminGroup:'https://aurora.dotcom.tn/api/GroupAdmin',
  url_Get_RoomPrices_Of_Contrat:'https://aurora.dotcom.tn/api/Room_priceParContrat/',
  url_Get_RoomTypePrices_Of_Contrat:'https://aurora.dotcom.tn/api/RoomType_priceParContrat/',
  url_Get_BoardPrices_Of_Contrat:'https://aurora.dotcom.tn/api/Arrangement_pricesParContrat/',
  URLPut_Board:'https://aurora.dotcom.tn/api/Arrangement_pricesParContrat/',
  url_Post_Market:'https://aurora.dotcom.tn/api/Market',
  url_Get_Market:'https://aurora.dotcom.tn/api/Market',
  url_Put_Market:'https://aurora.dotcom.tn/api/Market',
  url_delete_market:'https://aurora.dotcom.tn/api/Market',
  urlImageHotel: "https://aurora.dotcom.tn/api/Images/Hotel/",
  urlImageAirLine: "https://aurora.dotcom.tn/api/Images/AirLines/",

  
  url_Post_Owner:'https://aurora.dotcom.tn/api/Admin',
  url_Get_GroupAdmin:'https://aurora.dotcom.tn/api/GroupAdmin',
  url_ActivateOwner:'https://aurora.dotcom.tn/api/AdminActivate',
  url_Put_Owner:'https://aurora.dotcom.tn/api/Admin',
  url_Activategroupadmin:'https://aurora.dotcom.tn/api/GroupAdminActivate',
  url_Get_ModuleClient:'https://aurora.dotcom.tn/api/ModuleClient',
  url_put_ModuleClient:'https://aurora.dotcom.tn/api/ModuleClient',
  url_Post_Config:'https://aurora.dotcom.tn/api/Configuration/CustomiseHtml',
  url_Get_HtmlByAgence:'https://aurora.dotcom.tn/api/Configuration/GetHtmlByAgence',
  url_Activate_header:'https://aurora.dotcom.tn/api/Configuration/ActivateHeader',
  url_Activate_footer:'https://aurora.dotcom.tn/api/Configuration/ActivateFooter',
  urlActivateGroupAdmin:'https://aurora.dotcom.tn/api/GroupAdminActivate/',
  url_get_driver:'https://aurora.dotcom.tn/api/Transfer_Driver',
  url_Post_driver:'https://aurora.dotcom.tn/api/Transfer_Driver',
  url_Put_driver:'https://aurora.dotcom.tn/api/Transfer_Driver',
  url_Delete_driver:'https://aurora.dotcom.tn/api/Transfer_Driver',
  url_get_price:'https://aurora.dotcom.tn/api/Transfer_Price',
  url_Post_price:'https://aurora.dotcom.tn/api/Transfer_Price',
  url_update_price:'https://aurora.dotcom.tn/api/Transfer_Price',
  url_Delete_price:'https://aurora.dotcom.tn/api/Transfer_Price',
  url_get_area_:'https://aurora.dotcom.tn/api/Transfer_Area',
  url_Post_area:'https://aurora.dotcom.tn/api/Transfer_Area',
  url_Put_area:'https://aurora.dotcom.tn/api/Transfer_Area',
  url_Delete_area:'https://aurora.dotcom.tn/api/Transfer_Area',
  url_get_Period:'https://aurora.dotcom.tn/api/Transfer_Availability',
  url_Post_Period:'https://aurora.dotcom.tn/api/Transfer_Availability',
  url_Put_Period:'https://aurora.dotcom.tn/api/Transfer_Availability',
  url_Delete_Period:'https://aurora.dotcom.tn/api/Transfer_Availability',
  url_get_transfer:'https://aurora.dotcom.tn/api/Transfer',
  url_get_transporter:'https://aurora.dotcom.tn/api/Transfer_Transporter',
  url_Post_Transporter:'https://aurora.dotcom.tn/api/Transfer_Transporter',
  url_Put_Transporter:'https://aurora.dotcom.tn/api/Transfer_Transporter',
  url_Delete_Transporter:'https://aurora.dotcom.tn/api/Transfer_Transporter',
  url_get_location:'https://aurora.dotcom.tn/api/Transfer_Location',
  url_get_locationAuto:'https://aurora.dotcom.tn/api/Transfer_Location/Area',
  url_Post_location:'https://aurora.dotcom.tn/api/Transfer_Location',
  url_Put_location:'https://aurora.dotcom.tn/api/Transfer_Location',
  url_Delete_location:'https://aurora.dotcom.tn/api/Transfer_Location',
  url_Post_Transfer:'https://aurora.dotcom.tn/api/Transfer',
  url_Put_Transfer:'https://aurora.dotcom.tn/api/Transfer',
  url_Delete_Transfer:'https://aurora.dotcom.tn/api/Transfer',
  url_get_vehicle:'https://aurora.dotcom.tn/api/Transfer_Vehicle',
  url_get_provider:'https://aurora.dotcom.tn/api/Transfer_Provider',
  url_get_area:'https://aurora.dotcom.tn/api/Transfer_Area/City',
  url_get_category:'https://aurora.dotcom.tn/api/Transfer_Category',
  url_Post_category:'https://aurora.dotcom.tn/api/Transfer_Category',
  url_Put_category:'https://aurora.dotcom.tn/api/Transfer_Category',
  url_Delete_category:'https://aurora.dotcom.tn/api/Transfer_Category',
  url_Post_vehicle:'https://aurora.dotcom.tn/api/Transfer_Vehicle',
  url_Put_vehicle:'https://aurora.dotcom.tn/api/Transfer_Vehicle',
  url_Delete_vehicle:'https://aurora.dotcom.tn/api/Transfer_Vehicle',
  url_Post_Providrer:'https://aurora.dotcom.tn/api/Transfer_Provider',
  url_update_Provider:'https://aurora.dotcom.tn/api/Transfer_Provider',
  url_Delete_Provider:'https://aurora.dotcom.tn/api/Transfer_Provider',
  url_get_booking:'https://aurora.dotcom.tn/api/Transfer/booking',
  urlPrintVoucherTransfer:'https://aurora.dotcom.tn/api/transfer_voucher/V5_transfer_voucher_',
  url_cancelTransfer:'https://aurora.dotcom.tn/api/Transfer/booking/cancel',
  urlSearchTransfer:'https://aurora.dotcom.tn/api/Transfer_Search',
  urlBokingTransfer:'https://aurora.dotcom.tn/api/Transfer/booking',
  CreditLibanaisPaymentLink:'https://aurora.dotcom.tn/api/CreditLibanaisPayment/link',
  url_confirmBooking:'https://aurora.dotcom.tn/api/Transfer/Booking/Confirm',
  //url_Post_imagetransfer:'https://aurora.dotcom.tn/api/TransferImageUpload',
  urlFileUploadImageTransfer:'https://aurora.dotcom.tn/api/TransferImageUpload',
  url_get_concellation:'https://aurora.dotcom.tn/api/Transfer/Policy',
  url_Post_concellation:'https://aurora.dotcom.tn/api/Transfer/Policy',
  url_put_concellation:'https://aurora.dotcom.tn/api/Transfer/Policy',
  url_delete_concellation:'https://aurora.dotcom.tn/api/Transfer/Policy',
  url_get_alllocation_tour:'https://aurora.dotcom.tn/api/Tour_Location',
  url_Post_location_tour:'https://aurora.dotcom.tn/api/Tour_Location',
  url_Put_location_tour:'https://aurora.dotcom.tn/api/Tour_Location',
  url_get_location_tour:'https://aurora.dotcom.tn/api/Tour_Location/Country',
  url_Delete_location_tour:'https://aurora.dotcom.tn/api/Tour_Location',
  url_get_location_tourall:'https://aurora.dotcom.tn/api/Tour_Location',
  url_get_category_tour:'https://aurora.dotcom.tn/api/Tour_Category',
  url_get_provider_tour:'https://aurora.dotcom.tn/api/Tour/Provider',
  url_Post_Providrer_tour:'https://aurora.dotcom.tn/api/Transfer_Provider',
  url_update_Provider_tour:'https://aurora.dotcom.tn/api/Tour/Provider',
  url_Delete_Provider_tour:'https://aurora.dotcom.tn/api/Tour/Provider',
  url_get_price_tour:'https://aurora.dotcom.tn/api/Tour_Price',
  url_Post_price_tour:'https://aurora.dotcom.tn/api/Tour_Price',
  url_update_price_tour:'https://aurora.dotcom.tn/api/Tour_Price',
  url_Delete_price_tour:'https://aurora.dotcom.tn/api/Tour_Price',
  url_get_tour:'https://aurora.dotcom.tn/api/Tour',
  url_Post_tour:'https://aurora.dotcom.tn/api/Tour',
  url_Put_tour:'https://aurora.dotcom.tn/api/Tour',
  url_Delete_tour:'https://aurora.dotcom.tn/api/Tour',
 // url_get_category_tour:'https://aurora.dotcom.tn/api/Tour_Category',
  url_post_category_tour:'https://aurora.dotcom.tn/api/Tour_Category',
  url_put_category_tour:'https://aurora.dotcom.tn/api/Tour_Category',
  url_delete_category_tour:'https://aurora.dotcom.tn/api/Tour_Category',
  url_Post_Period_tour:'https://aurora.dotcom.tn/api/Tour/Availability',
  url_Put_Period_tour:'https://aurora.dotcom.tn/api/Tour/Availability',
  url_Delete_Period_tour:'https://aurora.dotcom.tn/api/Tour/Availability',
  url_get_Period_tour:'https://aurora.dotcom.tn/api/Tour/Availability',
  url_get_concellation_tour:'https://aurora.dotcom.tn/api/Tour/Policy',
  url_Post_concellation_tour:'https://aurora.dotcom.tn/api/Tour/Policy',
  url_put_concellation_tour:'https://aurora.dotcom.tn/api/Tour/Policy',
  url_delete_concellation_tour:'https://aurora.dotcom.tn/api/Tour/Policy',
  url_confirmBooking_tour:'https://aurora.dotcom.tn/api/Tour/Booking/Confirm',
  url_cancelTour:'https://aurora.dotcom.tn/api/Tour/Booking/Cancel',
  url_get_booking_tour:'https://aurora.dotcom.tn/api/Tour/booking',
  urlSearchTour:'https://aurora.dotcom.tn/api/Tour_Search',
  url_Booking_Tour:'https://aurora.dotcom.tn/api/Tour/booking',
  urlImageTransfer: "https://aurora.dotcom.tn/api/Images/Transfer/",
  urlFileUploadImageTour:'https://aurora.dotcom.tn/api/TourImageUpload',
  urlImageTour: "https://aurora.dotcom.tn/api/Images/Tour/",
  url_Post_voucher: "https://aurora.dotcom.tn/api/Transfer/Voucher",
  url_Post_voucher_tour: "https://aurora.dotcom.tn/api/Tour/voucher",
  url_get_vouchertour_:'https://aurora.dotcom.tn/api/tour_voucher/V5_tour_voucher_',
  url_put_periode_contrat_board: "https://aurora.dotcom.tn/api/BoardContratPeriod",
  url_Post_groupuser: "https://aurora.dotcom.tn/api/GroupUser",
  url_get_groupuser: "https://aurora.dotcom.tn/api/GroupUser",
  url_put_groupuser:"https://aurora.dotcom.tn/api/GroupUser",
  url_delete_groupuser:"https://aurora.dotcom.tn/api/GroupUser",
  url_Get_Module_acces:"https://aurora.dotcom.tn/api/UserAccess",
  url_Get_Active_Contract:"https://aurora.dotcom.tn/api/readActiveContract",

  


  url_Get_Boards_Of_Contrat_Extrat:"https://aurora.dotcom.tn/api/ExtraBoardContratParContrat",
  url_Put_BoardExtraOf_Contrat:"https://aurora.dotcom.tn/api/ExtraBoardParContrat",
  url_get_BoardExtratOfPeriod:"https://aurora.dotcom.tn/api/ExtraBoardContratPerPeriod",
  url_Get_ListSpecialOffer:"https://aurora.dotcom.tn/api/specialoffer/findByContrat",
  url_Put_order:"https://aurora.dotcom.tn/api/orderSpecialOffre",
  url_putimage:"https://aurora.dotcom.tn/api/Images/Hotel",

  url_saveRoom_Prices_all:"https://aurora.dotcom.tn/api/AllPrices",
  url_update_Prices_all:"https://aurora.dotcom.tn/api/UpdateAllPrices",

  //Urls Static Data Xmls
  ListHotelOcatasoft:"https://aurora.dotcom.tn/api/xml/ListHotelOcatasoft",
  ListCityOcatasoft:"https://aurora.dotcom.tn/api/xml/ListCityOcatasoft",
  getAllHotelsByCountryAndCityBooster:"https://aurora.dotcom.tn/api/xml/getAllHotelsByCountryAndCityBooster",
  getAllCitiesByCountryBooster:"https://aurora.dotcom.tn/api/xml/getAllCitiesByCountryBooster",
  getAllCountriesBooster:"https://aurora.dotcom.tn/api/xml/getAllCountriesBooster",
  ListCountriesCitiesCyberesa:"https://aurora.dotcom.tn/api/xml/ListCountriesCitiesCyberesa",
  ListHotelCyberesa:"https://aurora.dotcom.tn/api/xml/ListHotelCyberesa",


  AllHotelsByCountryAndCityDotcom:"https://aurora.dotcom.tn/api/xml/getAllHotelsByCountryAndCityDotcom",
  AllCitiesByCountryDotcom:"https://aurora.dotcom.tn/api/xml/getAllCitiesByCountryDotcom",
  AllCountriesDotcom:"https://aurora.dotcom.tn/api/xml/getAllCountriesDotcom",


}


