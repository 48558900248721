import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRoute ,ActivatedRouteSnapshot,RouterStateSnapshot} from '@angular/router';
import { AuthService } from './auth.service';
import { JWTTokenService } from './JWTToken.service';
import { Observable } from 'rxjs'; 
import { LoginService } from './login/login.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(
    public auth: AuthService, 
    public router: Router, 
    private route: ActivatedRoute,
    private jwtService: JWTTokenService,
    public loginService: LoginService,
  ) { }
  

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    
    if (this.jwtService.getUser()) {
      console.log("canActivate")
      if (this.jwtService.isTokenExpired()) {
        this.router.navigate(['login']);
        return false; 
      } else {
        return true; 
      }
    } else {
      
      this.router.navigate(['login']);
      return false; 
    }
  }
}
