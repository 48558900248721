import { Component, OnInit, ViewChild } from '@angular/core';
import { LayoutService } from '../../@vex/services/layout.service';
import { filter, map, startWith } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { checkRouterChildsData } from '../../@vex/utils/check-router-childs-data';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ConfigService } from '../../@vex/config/config.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SidebarComponent } from '../../@vex/components/sidebar/sidebar.component';
import { SidebarforfilterComponent } from 'src/@vex/components/sidebarforfilter/sidebarforfilter.component';
import { SearchingSendDataService } from '../pages/apps/searching/searching-send-data.service';
import { of } from 'rxjs';
import { LoginService } from '../../app/pages/pages/auth/login/login.service';
import { JWTTokenService } from '../../app/pages/pages/auth/JWTToken.service';


@UntilDestroy()
@Component({
  selector: 'vex-custom-layout',
  templateUrl: './custom-layout.component.html',
  styleUrls: ['./custom-layout.component.scss']
})
export class CustomLayoutComponent implements OnInit {

  sidenavCollapsed$ = this.layoutService.sidenavCollapsed$;
  isFooterVisible$ = this.configService.config$.pipe(map(config => config.footer.visible));
  isDesktop$ = this.layoutService.isDesktop$;
  ResultSearch:any
  FilterType
  isConditionTrue:boolean
  
  toolbarShadowEnabled$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    startWith(null),
    map(() => checkRouterChildsData(this.router.routerState.root.snapshot, data => data.toolbarShadowEnabled))
  );

  @ViewChild('configpanel', { static: true }) configpanel: SidebarComponent;
  @ViewChild('configpanelfilter', { static: true }) configpanelfilter: SidebarforfilterComponent;

  constructor(private layoutService: LayoutService,
              private configService: ConfigService,
              private breakpointObserver: BreakpointObserver,
              private router: Router,
              private LoginService: LoginService,
              private jwttokenservice: JWTTokenService,

              private SearchingSendData:SearchingSendDataService) { }

  ngOnInit() {
    this.getRole()

  }
     getRole() {
                  this.LoginService.currentrole.subscribe((role) => {

                    if(role==='admin' || this.jwttokenservice.getUser()=='info@rafahiabooking.com' || this.jwttokenservice.getUser()=='reservation@rafahiabooking.com'){
                      this.isConditionTrue=true
  
  }
  else{
    this.isConditionTrue=false

  }
              
                  });
                }
}
